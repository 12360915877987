.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-gap: 12px;
}

.center {
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
}

.card {
    position: relative;
    padding: 18px;
}

.close {
    position: absolute;
    top: -11px;
    right: 50%;
    transform: translateX(12px);
    width: 24px;
    height: 24px;
    color: #e1fbff;
    background-color: #4899a7;
    border: 1px solid #79deed;
    line-height: 24px;
    font-weight: bold;
    border-radius: 50%;
    &:hover {
        background-color: #58bbcd;
    }
}

.selectedCard {
    border-color: #79deed;
}

.name {
    font-size: 24px;
    font-family: var(--title-font-family);
}

.symbol {
    font-size: 12px;
    font-family: var(--mono-font-family);

}

.image {
    margin: 8px 0;
    img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
}

.multiplier {
    font-family: var(--mono-font-family);
    font-size: 14px;
    .number {
        font-size: 16px;
        color: #78dbff;
    }
}
