.deposit {
    display: flex;
    justify-content: space-between;
    
    &:hover {
        text-decoration: underline;
    }
    margin-top: 2px;
}

.title {
    text-align: center;
    font-family: var(--title-font-family);
    margin-bottom: 6px;
}

.deposits {
    font-size: 12px;
    font-family: var(--mono-font-family);
}

.help {
    text-align: center;
    margin-top: 8px;
}