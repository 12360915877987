.container {
    width: 325px;
}

.title {
    font-family: var(--mono-font-family);
    color: #38bed3;
    font-weight: normal;
    text-align: center;
    font-size: 21px;
    letter-spacing: 0.05em;
    margin: 0;
}

.description {
    text-align: center;
    font-size: 14px;
    color: #e4deff;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 18px;
    font-family: var(--nunito-font-family);
}

.inputContainer {
    margin-top: 8px;
    position: relative;
    width: 100%;
    height: 48px;
}

.input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 16px;
    appearance: none;
    font-size: 14px;
    border: 1px solid #096d7c;
    border-radius: 4px;
    background-color: #032328;
    color: #e9e4ff;
    font-family: var(--mono-font-family);
    transition: all 0.255s ease-in-out;

    &::placeholder {
        color: #e9e4ff;
    }

    &:active, &:focus {
        outline: 0;
        border-color: #096d7c;
    }
}

.inputMaxButton {
    position: absolute;
    top: 13px;
    right: 14px;
    font-size: 12px;
    appearance: none;
    background: transparent;
    font-family: var(--mono-font-family);
    color: #e9e4ff7e;
    border: 1px solid #096d7c8e;
    padding: 4px;
    line-height: 1;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        border: 1px solid #096d7c;
        color: #e9e4ff;
    }
}

.lockTime {
    margin-top: 4px;
    font-size: 12px;
    color: #38bed3;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.apyContainer {
    margin-top: 12px;
    padding: 0 8px;
}

.apy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: var(--mono-font-family);
    font-weight: normal;
    color: #38bed3;
}

.apyPercentage {
    font-size: 21px;
    font-weight: normal;
    color: #38bed3;
    font-family: var(--mono-font-family);
}

.apySuffix {
    font-size: 11px;
    margin-left: 6px;
    font-family: var(--mono-font-family);
    font-weight: normal;
    color: #38bed3;
}

.seperator {
    height: 1px;
    background-color: #38bed33d;
    margin: 8px 0 12px 0;
}

.apyResultLabel {
    font-size: 14px;
}

.apyResultValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
}

.apyResult {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 21px;
    font-weight: normal;
    font-family: var(--mono-font-family);
    line-height: 1;
    color: #e2dcfc;
    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 8px;
}

.lockRelease {
    color: #38bed3;
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
    font-family: var(--mono-font-family);
    font-weight: normal;
}

.button {
    margin: 21px auto 0 auto;
    display: block!important;
}


.successModalTitle {
    font-size: 24px;
    text-align: center;
    margin: 0;
}

.successModalDescription {
    margin: 0;
    margin-top: 8px;
    text-align: center;
}

.successModalLogo {
    margin-top: 14px;
    align-self: center;
}
