.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.198);
    z-index: 1000;
    opacity: 0;
    overflow-y: scroll;
    transition: all 0.255s ease-in-out;
    
    &.afterOpen {
        opacity: 1;
    }

    &.beforeClose {
        opacity: 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 1px solid #441150;
    background: #370c3b;
    background: linear-gradient(135deg, #370c3b 0%, #050142 50%, #370c3b 100%);
    color: var(--color-text-primary);
    min-height: 100px;
    min-width: 255px;
    max-width: 750px;
    overflow: auto;
    border-radius: 12px;
    outline: none;
    padding: 36px;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.255s ease-in-out;

    @media (max-width: 655px) {
        max-width: 100%;
        max-height: 100%;
    }

    &.afterOpen {
        opacity: 1;
        transform: scale(1);
    }

    &.beforeClose {
        opacity: 0;
        transform: scale(0.8);
    }

    .closeButton {
        position: absolute;
        top: 12px;
        right: 12px;
        appearance: none;
        background: transparent;
        padding: 0;
        line-height: 1;
        border: 0;
        z-index: 100;
        cursor: pointer;
        &:hover {
            .closeIcon path {
                fill: #a9a6b8;
            }
        }
    }

    .closeIcon {
        
        height: 18px;
        path {
            fill: #6341d3;
        }
    }
}