.container {
    display: block;
    padding: 24px;
    border-radius: 24px;
    background: var(--solice-card-gradient);
    border: 1px solid #0b7a8b;
    box-shadow: 0 2px 4px #051d20;
    color: var(--color-text-primary);
    transition: all 0.255s ease-in-out;
    &.isLink, &.hasOnClick {
        cursor: pointer;
    }
}