.container {
    display: inline-flex;
    position: relative;
    border: 1px solid #2d3085;
    appearance: none;
    padding: 13px 34px;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    font-family: var(--title-font-family);
    font-size: 16px;
    letter-spacing: 0.02em;
    background: linear-gradient(90deg, rgb(69, 180, 212), rgb(86, 78, 216));  
    box-shadow: 1px 2px 16px 3px rgba(54, 54, 54, 0.5); 
    
    &:hover {
        background: linear-gradient(90deg, rgb(77, 200, 235), rgb(97, 89, 240));  
    }
    &:active {
        background: linear-gradient(90deg, rgb(65, 173, 202), rgb(79, 73, 202));  
    }

    &.disabled {
        background: linear-gradient(90deg, hsl(193deg 2% 25%), hsl(243deg 4% 38%));
        border-color:  hsl(193deg 2% 25%);
        cursor: unset;
    }
}