.container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 24px;
    max-width: var(--container-max-width);
    width: 100%;
    margin: 24px auto;

    @media (max-width: 680px) {
        grid-template-columns: auto;
    }
}

.calculator {
    @media (max-width: 680px) {
        width: 100%!important;
    }
}

.description {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    font-size: 18px;
    a {
        color: #70defa;
        &:hover {
            text-decoration: underline;
        }
    }
}

.helpText {
    max-width: 325px;
    font-size: 12px;
    text-align: center;
    margin-top: 8px;

    @media (max-width: 680px) {
        max-width: 100%!important;
    }
}

.deposits {
    margin-top: 8px;
    max-width: 325px;
    @media (max-width: 680px) {
        max-width: 100%!important;
    }
}